import { useSdk } from '~/sdk';
import type { GetCountriesResponse } from '@vue-storefront/prestashop-api';

interface UseCountries {
  data: GetCountriesResponse['psdata']['countries'] | null;
  pending: boolean;
  error: any;
}

export function useCountries() {
  const { locale } = useI18n();

  const state = useState<UseCountries>(() => ({
    data: [],
    pending: false,
    error: null,
  }));

  async function fetchCountries() {
    state.value.pending = true;
    const { data, error } = await useAsyncData(() => useSdk().commerce.getCountries());
    useHandleError(error.value);
    state.value.data = data.value;
    state.value.pending = false;
  }

  watch(locale, fetchCountries);

  return {
    ...toRefs(state.value),
    execute: fetchCountries,
  };
}
